.pagination {
  margin-top: 20px;
  float: right;
}

.pagination .page-item {
  margin-right: 15px;
}

.pagination .page-item:last-of-type {
  margin-right: 0;
}

.pagination .page-item.active .page-link {
  color: #ffffff;
  background-color: #bdbdbd;
  background-color: #F4730E;
  border-color: #F4730E;
}

.pagination .page-link {
  color: #000000;
  background-color: #bdbdbd;
  border-radius: 50% !important;
}
