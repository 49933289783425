.form-control { padding: 15px;}

a.social-icon-blog-detail {
    color: #fff !important;
  background-color: #8c8c8c !important;
  padding: 8px 6px !important;
  border-radius: 24px !important;
  width: 25px !important;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  text-decoration: none;
}

.avatar-blog-details{
    width: 60px !important;
    height: 60px !important;
    margin-top: 8px;
}
.dropdown-toggle-most-recent::after {
    content: "\f107"; /* Assuming this is the correct code for the desired icon */
    font-family: 'FontAwesome'; /* Set the Font Awesome font family */
    border-top: 0em;
    vertical-align: 0em;
    color: #9D9DB0;
    background-color: #fff;
  }
.dropdown-toggle-most-recent:hover::after {
    content: "\f107"; /* Assuming this is the correct code for the desired icon */
    font-family: 'FontAwesome'; /* Set the Font Awesome font family */
    border-top: 0em;
    vertical-align: 0em;
    color: #6c6c77;
    background-color: #fff;
  }
  .nav-link-most-recent{
    color: #9D9DB0;
    background-color: #F4F4FA;
    border-radius: 5px;
    font-size: 20px;
    
  }
  .nav-link-most-recent:hover{
    color: #6c6c77;
    background-color: #F4F4FA;
    border-radius: 5px;
    font-size: 20px;
    
  }
  .blog-detail-button{
    background-color: #2F2E2E!important;
    color: #fff;
    border-radius: 0px;
  }
  .blog-detail-button:hover{
    background-color: #000 !important;
    color: #fff;
  }
  #checkbox {
    width: 23px; /* Set the desired width */
    height: 23px; /* Set the desired height */
    border: 2px solid #e7e7e7; /* Set the border color and thickness */
    border-radius: 0px; /* Set border radius for a rectangle */
    outline: none;
    cursor: pointer;
  }